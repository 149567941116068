import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import AnimateText from '../AnimateText/AnimateText';

const container = {
  visible: {
    transition: {
      staggerChildren: 0.025,
    },
  },
};

function AnimateTextInView({ text, type, className = '' }) {
  const { inView, ref } = useInView({ triggerOnce: true });
  return (
    <motion.div
      ref={ref}
      initial='hidden'
      animate={inView ? 'visible' : 'hidden'}
      variants={container}
    >
      <AnimateText className={`${className}`} text={text} type={type} />
    </motion.div>
  );
}

export default AnimateTextInView;
